import CommonView from './Common.js'

const HomeView = CommonView.extend({

  namespace: 'home',
  onEnter: function () {
    CommonView.onEnter()
  },
  onEnterCompleted: function () {
    CommonView.onEnterCompleted()
  },
  onLeave: function () {
    CommonView.onLeave()
  },
  onLeaveCompleted: function () {
    CommonView.onLeaveCompleted()
  }
})

export default HomeView
