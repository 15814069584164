import CommonView from './Common.js'

const MgmtView = CommonView.extend({

  namespace: 'mgmt',
  onEnter: function () {
    CommonView.onEnter()
  },
  onEnterCompleted: function () {
    CommonView.onEnterCompleted()
  },
  onLeave: function () {
    CommonView.onLeave()
  },
  onLeaveCompleted: function () {
    CommonView.onLeaveCompleted()
  }
})

export default MgmtView
