// Imports
import Barba from 'barba.js'
import FadeTransition from '../js/modules/Transition.js'

// Barba Views
import CommonView from './views/Common.js'
import HomeView from './views/Home.js'
import MgmtView from './views/Management.js'
import PublishingView from './views/Publishing.js'
import ArtistView from './views/Artist.js'
import TourView from './views/Tour.js'

// Initialize views
CommonView.init()
HomeView.init()
MgmtView.init()
PublishingView.init()
ArtistView.init()
TourView.init()

Barba.Prefetch.init()
Barba.Pjax.start()

Barba.Pjax.getTransition = () => {
  return FadeTransition
}
