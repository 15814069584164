import Barba from 'barba.js'
import anime from 'animejs'

const FadeTransition = Barba.BaseTransition.extend({
  start: function () {
    // As soon the loading is finished and the old page is faded out, let's fade the new page
    Promise.all([this.newContainerLoading]).then(this.fadeIn.bind(this))
  },

  fadeIn: function () {
    // Scroll to top
    window.scrollTo({top: 0})

    var _this = this
    const newBarba = this.newContainer
    const oldBarba = this.oldContainer

    newBarba.style.visibility = 'visible'
    newBarba.style.opacity = '0'

    // Animate the transition from opacity 0 to 1
    anime({
      targets: newBarba,
      opacity: 1,
      duration: 250,
      easing: 'cubicBezier(0.645, 0.045, 0.355, 1)',
      begin: function (anim) {
        oldBarba.style.display = 'none'
      },
      complete: function (anim) {
        _this.done()
      }
    })
  }
})

export default FadeTransition
