import CommonView from './Common.js'
import * as Collapse from '../helpers/collapse'
import Flickity from 'flickity'

let flkty

const ArtistView = CommonView.extend({
  namespace: 'artist',
  onEnter: function () {
    // (Re)load songkick widget script
    loadScript('script-songkick', 'https://widget.songkick.com/widget.js')
    loadScript('script-bandsintown', 'https://widget.bandsintown.com/main.min.js')

    CommonView.onEnter()
  },

  onEnterCompleted: function () {
    // Initialize Flickity
    flkty = new Flickity('.artist-gallery', {
      // options
      wrapAround: true,
      autoPlay: 3500
    })

    // Open and close categories when clicked
    const toggles = Array.from(document.querySelectorAll('.toggle'))
    // Listen for click events
    toggles.forEach((toggle) => {
      toggle.addEventListener('click', function (event) {
        event.preventDefault()
        toggle.classList.toggle('is-visible')
        const content = toggle.parentNode.querySelector('.toggle-content')
        Collapse.toggle(content)
      }, false)
    })

    // Fill list square when hovering category link
    const projectLinks = document.querySelectorAll('.category-content a')
    projectLinks.forEach((link) => {
      const listSquare = link.parentNode.parentNode.querySelector('a span.list-square')

      link.addEventListener('mouseover', () => {
        if (!listSquare) return
        listSquare.classList.add('is-active')
      })

      link.addEventListener('mouseout', () => {
        if (!listSquare) return
        listSquare.classList.remove('is-active')
      })
    })

    // Print helpers
    const togglesPrint = Array.from(document.querySelectorAll('.artist__print .toggle'))
    const mediaQueryList = window.matchMedia('print')

    mediaQueryList.addListener(function (mql) {
      if (mql.matches) {
        if (!togglesPrint) return
        togglesPrint.forEach((toggle) => {
          toggle.classList.toggle('is-visible')
          const content = toggle.parentNode.querySelector('.toggle-content')
          content.classList.add('is-visible')
          // Collapse.toggle(content)
        })
      }
    })

    CommonView.onEnterCompleted()
  },

  onLeave: function () {
    // Remove widget scripts
    removeNode('.script-songkick')
    removeNode('.script-bandsintown')
    CommonView.onLeave()
  },

  onLeaveCompleted: function () {
    CommonView.onLeaveCompleted()
  }
})

export default ArtistView

function loadScript (selector, src) {
  // Check for existing script element and delete it if it exists
  let script = document.querySelector(selector)
  if (script !== null) {
    document.body.removeChild(script)
    console.info('---------- Script refreshed ----------')
  }

  // Create new script element and load a script into it
  script = document.createElement('script')
  script.src = src
  script.classList.add(selector)
  document.body.appendChild(script)
}

function removeNode (selector) {
  const node = document.querySelector(selector)
  node.parentNode.removeChild(node)
}
