const transitionDuration = 350

// Show an element
const show = (elem) => {
  // Get the natural height of the element
  const getHeight = () => {
    elem.style.display = 'block' // Make it visible
    let height = elem.scrollHeight + 'px' // Get it's height
    elem.style.display = '' //  Hide it again
    return height
  }

  const height = getHeight() // Get the natural height
  elem.classList.add('is-visible') // Make the element visible
  elem.style.height = height // Update the max-height

  // Once the transition is complete, remove the inline max-height so the content can scale responsively
  window.setTimeout(function () {
    elem.style.height = ''
  }, transitionDuration)
}

// Hide an element
const hide = (elem) => {
  // Give the element a height to change from
  elem.style.height = elem.scrollHeight + 'px'

  // Set the height back to 0
  window.setTimeout(function () {
    elem.style.height = '0'
  }, 25) // wait 25ms to avoid jump issue

  // When the transition is complete, hide it
  window.setTimeout(function () {
    elem.classList.remove('is-visible')
  }, transitionDuration)
}

// Toggle element visibility
const toggle = (elem) => {
  // If the element is visible, hide it
  if (elem.classList.contains('is-visible')) {
    hide(elem)
    return
  }
  // Otherwise, show it
  show(elem)
}

export { show, hide, toggle }
