import CommonView from './Common.js'

const PublishingView = CommonView.extend({

  namespace: 'publishing',
  onEnter: function () {
    CommonView.onEnter()
  },
  onEnterCompleted: function () {
    CommonView.onEnterCompleted()
  },
  onLeave: function () {
    CommonView.onLeave()
  },
  onLeaveCompleted: function () {
    CommonView.onLeaveCompleted()
  }
})

export default PublishingView
